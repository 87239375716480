import React, { useState, FormEvent } from 'react'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'

import config from '../config'
import { useNavigate } from 'react-router-dom'
import Stack from '@mui/material/Stack'
import { EmailField } from '../components/form/EmailField'
import { PasswordField } from '../components/form/PasswordField'
import { UsernameField } from '../components/form/UsernameField'
import { FormTitle } from '../components/form/FormTitle'
import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import { Login } from '@mui/icons-material'

const Signup = (): JSX.Element => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const onSubmit = (event: FormEvent): void => {
    event.preventDefault()

    const attributeUsername = new CognitoUserAttribute({ Name: 'preferred_username', Value: username })
    config.CognitoUserPool.signUp(email, password, [attributeUsername], [], (err, data) => {
      if (err != null) console.error(err)
      console.log(data)
      navigate('/')
    })
  }

  const SignupButton = (): JSX.Element => {
    return (
      <Tooltip title="Register to the Application">
        <Button type="submit" variant="outlined" startIcon={<Login />}>
          Register
        </Button>
      </Tooltip>
    )
  }

  return (
    <Stack
      component="form"
      sx={{
        border: '1px dashed grey',
        width: '35ch',
        alignItems: 'center',
        '& > :not(style)': { margin: 2, width: '30ch' }
      }}
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
    >
      <FormTitle text={'Register'} />
      <EmailField email={email} setEmail={setEmail} />
      <PasswordField password={password} setPassword={setPassword} />
      <UsernameField username={username} setUsername={setUsername} />
      <SignupButton />
    </Stack>
  )
}

export default Signup
