import React from 'react'
import { ArrowBack, Logout, Menu as MenuIcon } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { useLocation, useNavigate } from 'react-router-dom'

import { SessionProps } from '../models'
import { accountUseContext } from './Account'

interface TopBarProps {
  sessionProps?: SessionProps
}

const TopBar = (props: TopBarProps): JSX.Element => {
  const navigate = useNavigate()
  const location = useLocation()

  const { sessionProps } = props
  const { logout } = accountUseContext()

  // pages that don't have full menu and only show back button
  const leafPages = ['/login', '/signup']

  const BackButton = (): JSX.Element => {
    return (
      <Tooltip title="Back" className="leftCorner">
        <IconButton aria-label="Back" onClick={() => navigate(-1)}>
          <ArrowBack/>
        </IconButton>
      </Tooltip>
    )
  }

  const MenuButton = (): JSX.Element => {
    return (
      <PopupState variant="popover" popupId="main-menu">
      {(popupState) => (
        <React.Fragment>
          <IconButton aria-label="Menu" {...bindTrigger(popupState)}>
            <MenuIcon />
          </IconButton>
          <Menu {...bindMenu(popupState)}>
            <MenuItem onClick={() => {
              popupState.close()
              navigate('/')
            }}>Upload</MenuItem>
            <MenuItem onClick={() => {
              popupState.close()
              navigate('/player')
            }}>Play</MenuItem>
            <MenuItem onClick={() => {
              popupState.close()
              navigate('/editor')
            }}>Edit</MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
    )
  }

  const LogoutButton = (): JSX.Element => {
    return (
      <Tooltip title="Log Out from the Application" className="rightCorner">
        <IconButton aria-label="Back" onClick={logout}>
          <Logout/>
        </IconButton>
      </Tooltip>
    )
  }

  return (
      <div className="topBar">

        <span className="leftCorner">
          {leafPages.includes(location.pathname)
            ? (
            <BackButton />
              )
            : <MenuButton />}
        </span>
        <span className="rightCorner">
          {sessionProps !== undefined
            ? (
              <LogoutButton />
              )
            : <span />}
        </span>
      </div>
  )
}

export default TopBar
