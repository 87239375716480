import React from 'react'
import { AccountCircle } from '@mui/icons-material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

interface UsernameFieldProps {
  username: string
  setUsername: React.Dispatch<React.SetStateAction<string>>
}

export const UsernameField = (props: UsernameFieldProps): JSX.Element => {
  return (
    <TextField
      id="username"
      label="Username"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AccountCircle />
          </InputAdornment>
        )
      }}
      variant="outlined"
      value={props.username}
      onChange={(event) => props.setUsername(event.target.value)}
    />
  )
}
