import React from 'react'
import { Email } from '@mui/icons-material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

interface EmailFieldProps {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
}

export const EmailField = (props: EmailFieldProps): JSX.Element => {
  return (
    <TextField
      id="email"
      label="Email"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Email />
          </InputAdornment>
        )
      }}
      variant="outlined"
      value={props.email}
      onChange={(event) => props.setEmail(event.target.value)}
    />
  )
}
