import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'

import './App.css'

import { SessionProps } from './models'

import Account from './components/Account'
import Status from './components/Status'
import TopBar from './components/TopBar'

import Login from './pages/Login'
import Player from './pages/Player'
import Editor from './pages/Editor'
import Signup from './pages/Signup'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const darkTheme = createTheme({
  palette: {
    mode: 'dark'
  }
})

function App (): JSX.Element {
  const [sessionProps, setSessionProps] = useState<SessionProps | undefined>(undefined)

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="App">
        <Account setSessionProps={setSessionProps}>
          <TopBar sessionProps={sessionProps} />
          <Routes>
            <Route path="/" element={<Status sessionProps={sessionProps} setSessionProps={setSessionProps} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/player" element={<Player sessionProps={sessionProps}/>} />
            <Route path="/editor" element={<Editor sessionProps={sessionProps}/>} />
          </Routes>
        </Account>
      </div>
    </ThemeProvider>
  )
}

export default App
