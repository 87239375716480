
import React, { createContext, useContext } from 'react'
import { CognitoUser, CognitoUserSession, AuthenticationDetails } from 'amazon-cognito-identity-js'

import config from '../config'
import { useNavigate } from 'react-router-dom'

import { SessionProps } from '../models'

interface IAccountContext {
  authenticate: Function
  getSession: Function
  logout: React.MouseEventHandler
}

interface AccountProps {
  children: React.ReactNode
  setSessionProps: React.Dispatch<React.SetStateAction<SessionProps | undefined>>
}

const AccountContext = createContext<IAccountContext>({} as IAccountContext) // eslint-disable-line @typescript-eslint/consistent-type-assertions

export const accountUseContext = (): IAccountContext => useContext(AccountContext)

const Account = (props: AccountProps): JSX.Element => {
  const navigate = useNavigate()

  const getSession = async (): Promise<CognitoUserSession | null> => {
    return await new Promise((resolve, reject) => {
      const user = config.CognitoUserPool.getCurrentUser()
      if (user == null) {
        reject(new Error('Current user not found'))
        return
      }
      user.getSession((err: Error | null, session: CognitoUserSession | null) => {
        if (err != null) reject(err)
        else resolve(session)
      })
    })
  }

  const authenticate = async (Username: string, Password: string): Promise<CognitoUserSession | null> => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool: config.CognitoUserPool })

      const authDetails = new AuthenticationDetails({ Username, Password })

      user.authenticateUser(authDetails, {
        onSuccess: (data: CognitoUserSession) => {
          console.log('Logged in: ', data)
          resolve(data)
        },
        onFailure: (err) => {
          console.error('Failed to log in: ', err)
          reject(err)
        },
        newPasswordRequired: (data) => {
          console.log('newPasswordRequired: ', data)
          resolve(data)
        }
      })
    })
  }

  const logout = (): void => {
    const user = config.CognitoUserPool.getCurrentUser()
    if (user != null) {
      console.log('Logging out')
      user.signOut()
      props.setSessionProps(undefined)
      navigate('/')
    }
  }

  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout }}>
      {props.children}
    </AccountContext.Provider>
  )
}

export default Account
