import React from 'react'
import Stack from '@mui/material/Stack'
import ReactPlayer from 'react-player'

import { SessionProps } from '../models'

interface PlayerProps {
  sessionProps?: SessionProps
}

const Player = (props: PlayerProps): JSX.Element => {
  const { sessionProps } = props

  const getPlayer = (): JSX.Element => {
    const url = 'https://dev-binary-storage-f16bb4b.s3.eu-north-1.amazonaws.com/1b6928d6-3072-4550-b269-94507e7599c9?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEBgaCmV1LW5vcnRoLTEiRjBEAiAsjhTAlIxS1ZuK6hPEO8ChIO%2FtKJHgVZR64CMJGqcBkgIgE9iBc9%2BSNnjpsHuy4v%2Fs1EEqpiYbtfIWEFhXAFbBv6Qq%2FQIIMRADGgw4Mjc5MjU1ODY3NTUiDBmIy%2B2c%2Fa5FKyN1DiraAmxfo%2Bv5oHPGA7grAXv6BAItmzGnb0x65wpL4j6Rhq0dJP5OB2odPYgqlffXRVPNVPCHq45XHrM84c6kn8s2qqFKUi%2BXpDd0iHV0vwo%2FtGDbhUG4fxv7ffbPm%2Fs32S1d2KwWTVTBkgOSpjKGi8tut39OZHH86iHWXR8m0A7eJcAl57DNb%2FcYc%2BiQR7L9PAQbh%2FKLu%2Fe5ONiSGx3segJfTqIOa%2FOGHUAOWni4BKTeaSPOchGI81kio79MqNC8Q2sjM85Ih4kXq0QZm0Z9kuobpMVFCM%2BQ5QXejATcmLfPc74uXibAAaTMx7JqtHrdZ7x%2FyDg3YQKLF%2BJlv71v%2BkfyugCAZcudJ6Oxnh0rcQVY%2FIpsgGV4gl0Pn94hIBWjqZjD7C0AxHMgoWYKEFWWgkctVQiK4C5C4V7aX7lMuo7xJJHMj3%2B2c%2FhLuk9rMhsPYuL4SFI161PN%2BUW2US8w2OrZogY6tAL40SKEV%2B8PLdh125zsP4btSm%2BDnTgjXzRxyBCBKnPyPiPx7h%2BIVHYz4Zq4RWFvjeg61DwDI5rU%2FvSzgFhDbjpsMgFGEn8L5Ul6rAuDilM2KMctAkBML2yd7oi%2Fch%2FG4WdbGx7Rgl7P%2B0OcSzLlTpDu9hWNI6j4WU6P5d72tk15I3YK9HNOz9A4P2ZcUzlRJf9uE0nKkOgIlLzRxK6eMohhZ27R%2BJB2FDHA4Hb4a2KJ8T%2B6J4aI5Ld%2FPWBGNWfynaZBA6IHUY3Sxhxbws85gz2Y3MuK2UwbN2QNRv0uI4RPWGBpBUNPuw%2Bm0kGalqYDkgjghGUW9frpgGIb07DqKKVccTTzvURvcpsSBRAOTFGJ88L09YXTC4mvb33G%2FCAOl8fdh3e6OMT2ybOjHpfj7EChPY3Bow%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230506T162241Z&X-Amz-SignedHeaders=host&X-Amz-Expires=21600&X-Amz-Credential=ASIA4BRBV4NBVWO3FL6E%2F20230506%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Signature=e75f28add1eb8745f99587b8e98a113fa367161c8039a514d33739ae7094a070&response-content-disposition=attachment;filename=foo.m4v'
    console.log(`Can play: ${String(ReactPlayer.canPlay(url))}`)
    if (sessionProps !== undefined) {
      if (sessionProps.groups.includes('sakeet')) {
        return (
          <Stack spacing={1}>
            <ReactPlayer
              width="100%"
              height="100%"
              playing={true}
              url={url}
              onError={(e, data) => console.log(`error: ${String(e)} - ${String(data)}`)}
              onReady={(player) => console.log('ready')}
              onStart={() => console.log('start')}
              onPlay={() => console.log('play')}
              controls={true}
            />
          </Stack>
        )
      }
      return <span>Please wait until registered!</span>
    }
    // this shouldn't happen
    return <span />
  }

  return (
    <div className="player">
    {getPlayer()}
    </div>
  )
}

export default Player
