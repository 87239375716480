import React, { useEffect } from 'react'
import { CognitoUserSession } from 'amazon-cognito-identity-js'

import { accountUseContext } from './Account'
import Landing from '../pages/Landing'
import Home from '../pages/Home'
import { SessionProps } from '../models'

interface StatusProps {
  sessionProps?: SessionProps
  setSessionProps: React.Dispatch<React.SetStateAction<SessionProps | undefined>>
}

const Status = (props: StatusProps): JSX.Element => {
  const { sessionProps, setSessionProps } = props

  const { getSession } = accountUseContext()

  useEffect(() => {
    const setSessionStatus = async (): Promise<void> => {
      const session: CognitoUserSession | null = await getSession()
      console.log('!!!Session: ', session)
      if (session === null) {
        return
      }
      const sessionPayload = session.getIdToken().payload
      const {
        preferred_username: username,
        email,
        email_verified: emailVerified,
        'cognito:groups': groups,
        'cognito:preferred_role': cognitoRole
      } = sessionPayload
      const idToken = session.getIdToken().getJwtToken()
      console.log('!!!Payload: ', sessionPayload)

      setSessionProps({
        username,
        email,
        emailVerified,
        groups,
        cognitoRole,
        idToken
      })
    }

    setSessionStatus()
      .catch(console.error)
  }, [])

  return (
      <div>
          {sessionProps !== undefined ? <Home sessionProps={sessionProps}/> : <Landing />}
      </div>
  )
}

export default Status
