import { Create, Login } from '@mui/icons-material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const Landing = (): JSX.Element => {
  const navigate = useNavigate()

  const LoginButton = (): JSX.Element => {
    return (
      <Tooltip title="Login to the Application">
        <Button
          variant="outlined"
          startIcon={<Login />}
          onClick={
            (): void => {
              navigate('/login')
            }
          }
        >
          Login
        </Button>
      </Tooltip>
    )
  }
  const RegisterButton = (): JSX.Element => {
    return (
      <Tooltip title="Register as a New User to the Application">
        <Button
          variant="outlined"
          startIcon={<Create />}
          onClick={
            (): void => {
              navigate('/signup')
            }
          }
        >
          Register
        </Button>
      </Tooltip>
    )
  }

  return (
    <Box
      sx={{
        '& > :not(style)': { margin: 2, width: '25ch' }
      }}
    >
      <LoginButton />
      <RegisterButton />
    </Box>
  )
}

export default Landing
