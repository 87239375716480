import React from 'react'
import { Password } from '@mui/icons-material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

interface PasswordFieldProps {
  password: string
  setPassword: React.Dispatch<React.SetStateAction<string>>
}

export const PasswordField = (props: PasswordFieldProps): JSX.Element => {
  return (
    <TextField
      id="password"
      label="Password"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Password />
          </InputAdornment>
        )
      }}
      variant="outlined"
      type="password"
      value={props.password}
      onChange={(event) => props.setPassword(event.target.value)}
    />
  )
}
