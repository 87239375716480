import { CloudUpload } from '@mui/icons-material'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import React, { FormEvent, useState } from 'react'

import config from '../config'
import { SessionProps } from '../models'
import { FormTitle } from './form/FormTitle'
import { UploadField } from './form/UploadField'

interface TopBarProps {
  sessionProps?: SessionProps
}

const UploadForm = (props: TopBarProps): JSX.Element => {
  const { sessionProps } = props
  const [url, setUrl] = useState<string>('')

  const invokeUploadLambda = async (url: string): Promise<void> => {
    if (sessionProps == null) return
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: sessionProps.idToken
      },
      body: JSON.stringify({ url })
    }
    const response = await fetch(`${config.ApiBaseUrl}/upload`, requestOptions)
    const status = await response.status
    console.log('Upload response', status)
  }

  const upload = (event: React.FormEvent): void => {
    event.preventDefault()

    invokeUploadLambda(url)
      .then(() => {
        setUrl('')
      })
      .catch((err) => {
      // TODO: handle error
        console.error('Upload failed', err)
      })
  }

  const onSubmit = (event: FormEvent): void => {
    upload(event)
  }

  const UploadButton = (): JSX.Element => {
    return (
      <Tooltip title="Upload to Cloud">
        <Button type="submit" variant="outlined" startIcon={<CloudUpload />}>
          Upload
        </Button>
      </Tooltip>
    )
  }

  return (
      <Stack
        component="form"
        sx={{
          border: '1px dashed grey',
          width: '35ch',
          alignItems: 'center',
          '& > :not(style)': { margin: 2, width: '30ch' }
        }}
        noValidate
        autoComplete="off"
        onSubmit={onSubmit}
      >
        <FormTitle text={'Upload'} />
        <UploadField url={url} setUrl={setUrl} />
        <UploadButton />
      </Stack>
  )
}

export default UploadForm
