import React from 'react'

import { SessionProps } from '../models'
import UploadForm from '../components/UploadForm'

interface HomeProps {
  sessionProps?: SessionProps
}

const Home = (props: HomeProps): JSX.Element => {
  const { sessionProps } = props

  const welcome = (): JSX.Element => {
    if (sessionProps !== undefined) {
      if (sessionProps.groups.includes('admin')) {
        return <span>welcome admin!</span>
      }
      if (sessionProps.groups.includes('sakeet')) {
        return <UploadForm sessionProps={sessionProps} />
      }
      return <span>Please wait until registered!</span>
    }
    // this shouldn't happen
    return <span />
  }

  return (
    <div className="home">
    {welcome()}
    </div>
  )
}

export default Home
