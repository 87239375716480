import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'

import { SessionProps } from '../models'

interface EditorProps {
  sessionProps?: SessionProps
}

const Editor = (props: EditorProps): JSX.Element => {
  const { sessionProps } = props

  const getEditor = (): JSX.Element => {
    if (sessionProps !== undefined) {
      if (sessionProps.groups.includes('sakeet')) {
        return (
          <Stack spacing={1}>
            <span> Page is under construction... </span>
            <Skeleton variant="rectangular" width={200} height={50} />
            <Skeleton variant="rectangular" width={200} height={50} />
            <Skeleton variant="rectangular" width={200} height={50} />
          </Stack>
        )
      }
      return <span>Please wait until registered!</span>
    }
    // this shouldn't happen
    return <span />
  }

  return (
    <div className="editor">
    {getEditor()}
    </div>
  )
}

export default Editor
