import React, { useState, FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { Login as LoginMaterialIcon } from '@mui/icons-material'

import { accountUseContext } from '../components/Account'
import { EmailField } from '../components/form/EmailField'
import { PasswordField } from '../components/form/PasswordField'
import Tooltip from '@mui/material/Tooltip'
import Stack from '@mui/material/Stack'
import { FormTitle } from '../components/form/FormTitle'

const Login = (): JSX.Element => {
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { authenticate } = accountUseContext()

  const onSubmit = (event: FormEvent): void => {
    event.preventDefault()

    authenticate(email, password)
      .then((data: any) => {
        console.log('Logged in!', data)
        navigate('/')
      })
      .catch((err: Error) => {
        console.error('Failed to log in', err)
      })
  }

  const LoginButton = (): JSX.Element => {
    return (
      <Tooltip title="Login to the Application">
        <Button type="submit" variant="outlined" startIcon={<LoginMaterialIcon />}>
          Login
        </Button>
      </Tooltip>
    )
  }

  return (
    <div>
        <Stack
          component="form"
          sx={{
            border: '1px dashed grey',
            width: '35ch',
            alignItems: 'center',
            '& > :not(style)': { margin: 2, width: '30ch' }
          }}
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
        >
            <FormTitle text={'Login'} />
            <EmailField email={email} setEmail={setEmail} />
            <PasswordField password={password} setPassword={setPassword} />
            <LoginButton />
        </Stack>
    </div>
  )
}

export default Login
