import { CognitoUserPool } from 'amazon-cognito-identity-js'

const poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID
}

const config = {
  Environment: process.env.REACT_APP_ENV,
  ApiBaseUrl: process.env.REACT_APP_SAAS_API_BASE ?? `https://api.${process.env.REACT_APP_ENV}.säti.institute`,
  CognitoUserPool: new CognitoUserPool(poolData)
}

export default config
