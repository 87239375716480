import React from 'react'
import { YouTube } from '@mui/icons-material'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'

interface UploadFieldProps {
  url: string
  setUrl: React.Dispatch<React.SetStateAction<string>>
}

export const UploadField = (props: UploadFieldProps): JSX.Element => {
  return (
    <TextField
      id="youtube"
      label="Youtube"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <YouTube />
          </InputAdornment>
        )
      }}
      variant="outlined"
      value={props.url}
      placeholder="Enter youtube url"
      onChange={(event) => props.setUrl(event.target.value)}
    />
  )
}
