import React from 'react'
import Typography from '@mui/material/Typography'

export const FormTitle = (props: { text: string }): JSX.Element => {
  return (
    <Typography
      className="formTitle"
      variant="h5"
      color="textSecondary"
      sx={{
        textAlign: 'center'
      }}
    >
      {props.text}
    </Typography>
  )
}
